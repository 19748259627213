.Frame {
  height: 1000px;
}

.Chrome {
  height: 1000px;
}

.FMAASMediaPlayerBackButton {
  position: absolute;
  left: 85px;
  top: 405px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}

.FMAASRadioIcon {
  position: absolute;
  left: 335px;
  top: 160px;
  width: 55px;
  height: 50px;
  background-color: var(--button-color);
}

.FMAASRecentlyPlayed {
  position: absolute;
  left: 60px;
  top: 170px;
  width: 130px;
  height: 130px;
  background-color: var(--button-color);
}

.HomeButton {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}