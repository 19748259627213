.AppFrame {
  height: 1000px;
}

.AppChrome {
  height: auto;
  width: 1000px;
}

.AAEAAppsButton {
  position: absolute;
  left: 405px;
  top: 550px;
  width: 110px;
  height: 80px;
  background-color: var(--button-color);
}

.AAEAAudioButton {
  position: absolute;
  left: 0px;
  top: 550px;
  width: 110px;
  height: 80px;
  background-color: var(--button-color);
}


.AAEADiscoveryBackButton {
  position: absolute;
  left: 10px;
  top: 70px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}

.AAEAMediaPlayerBackButton {
  position: absolute;
  left: 15px;
  top: 120px;
  width: 180px;
  height: 60px;
  background-color: var(--button-color);
}

.AAEAAmazonIcon {
  position: absolute;
  left: 20px;
  top: 185px;
  width: 155px;
  height: 140px;
  background-color: var(--button-color);
}

.AAEAPandoraIcon {
  position: absolute;
  left: 192px;
  top: 185px;
  width: 155px;
  height: 140px;
  background-color: var(--button-color);
}

.AAEASpotifyIcon {
  position: absolute;
  left: 365px;
  top: 185px;
  width: 155px;
  height: 140px;
  background-color: var(--button-color);
}

.AAEARecentlyPlayed {
  position: absolute;
  left: 15px;
  top: 145px;
  width: 130px;
  height: 140px;
  background-color: var(--button-color);
}

.HomeButton {
  position: absolute;
  left: 10px;
  top: 70px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}