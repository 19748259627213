.Frame {
  height: 1000px;
}

.Chrome {
  height: 1000px;
}

.AAASTabs {
  position: absolute;
  height: 50px;
  width: 510px;
  left: 85px;
  top: 60px;
}

.AAASTabsTable {
  width: 100%;
  height: 100%;
}

.AAASTab {
  background-color: var(--button-color);
  width: 90%;
  height: 100%;
}

.AAASMediaPlayerBackButton {
  position: absolute;
  left: 85px;
  top: 405px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}

.AAASRadioIcon {
  position: absolute;
  left: 335px;
  top: 160px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}

.AAASRecentlyPlayed {
  position: absolute;
  left: 60px;
  top: 160px;
  width: 130px;
  height: 140px;
  background-color: var(--button-color);
}

.HomeButton {
  position: absolute;
  left: 15px;
  top: 10px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}