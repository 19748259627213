.AppFrame {
  height: 1000px;
}

.AppChrome {
  height: auto;
  width: 1000px;
}

.FMAEAAppsButton {
  position: absolute;
  left: 405px;
  top: 550px;
  width: 110px;
  height: 80px;
  background-color: var(--button-color);
}

.FMAEAAudioButton {
  position: absolute;
  left: 0px;
  top: 550px;
  width: 110px;
  height: 80px;
  background-color: var(--button-color);
}


.FMAEAMediaPlayerBackButton {
  position: absolute;
  left: 15px;
  top: 120px;
  width: 180px;
  height: 60px;
  background-color: var(--button-color);
}

.FMAEAFordAppIcon {
  position: absolute;
  left: 10px;
  top: 180px;
  width: 180px;
  height: 150px;
  background-color: var(--button-color);
}

.FMAEARecentlyPlayed {
  position: absolute;
  left: 15px;
  top: 145px;
  width: 130px;
  height: 135px;
  background-color: var(--button-color);
}

.HomeButton {
  position: absolute;
  left: 15px;
  top: 70px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}