.AppFrame {
  height: 1000px;
}

.AppChrome {
  height: auto;
  width: 1000px;
}

.FMAASMediaPlayerBackButton {
  position: absolute;
  left: 15px;
  top: 190px;
  width: 180px;
  height: 55px;
  background-color: var(--button-color);
}

.FMAASMediaPlayerSourcesButton {
  position: absolute;
  left: 15px;
  top: 125px;
  width: 180px;
  height: 55px;
  background-color: var(--button-color);
}

.FMAASRadioIcon {
  position: absolute;
  left: 355px;
  top: 180px;
  width: 180px;
  height: 150px;
  background-color: var(--button-color);
}

.FMAASRecentlyPlayed {
  position: absolute;
  left: 15px;
  top: 145px;
  width: 130px;
  height: 135px;
  background-color: var(--button-color);
}

.HomeButton {
  position: absolute;
  left: 15px;
  top: 70px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}