.blip {
  width: 100%;
  height: 100%;
  background-color: red;
  margin: 0px;
  opacity: 0;
  transform: scale(0.5);
  transition: transform 0.5s, opacity 0.5s;
}

.blip.animate {
  transform: scale(1.1);
  opacity: 0.5;
}