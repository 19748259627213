.Frame {
  height: 1000px;
}

.Chrome {
  height: 1000px;
}

.FMAEATabs {
  position: absolute;
  height: 50px;
  width: 430px;
  left: 100px;
  top: 75px;
}

.FMAEATabsTable {
  width: 100%;
  height: 100%;
}

.FMAEATab {
  background-color: var(--button-color);
  width: 90%;
  height: 100%;
}

.FMAEAMediaPlayerBackButton {
  position: absolute;
  left: 85px;
  top: 360px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}

.FMAEAFordAppIcon {
  position: absolute;
  left: 50px;
  top: 160px;
  width: 55px;
  height: 50px;
  background-color: var(--button-color);
}

.FMAEARecentlyPlayed {
  position: absolute;
  left: 60px;
  top: 110px;
  width: 130px;
  height: 140px;
  background-color: var(--button-color);
}

.HomeButton {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}