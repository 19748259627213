.Frame {
  height: 1000px;
}

.Chrome {
  height: 1000px;
}

.AAEATabs {
  position: absolute;
  height: 50px;
  width: 430px;
  left: 100px;
  top: 80px;
}

.AAEATabsTable {
  width: 100%;
  height: 100%;
}

.AAEATab {
  background-color: var(--button-color);
  width: 90%;
  height: 100%;
}

.AAEADiscoveryBackButton {
  position: absolute;
  left: 15px;
  top: 10px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}

.AAEAMediaPlayerBackButton {
  position: absolute;
  left: 85px;
  top: 355px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}

.AAEAAmazonIcon {
  position: absolute;
  left: 50px;
  top: 160px;
  width: 55px;
  height: 50px;
  background-color: var(--button-color);
}

.AAEAPandoraIcon {
  position: absolute;
  left: 140px;
  top: 160px;
  width: 55px;
  height: 50px;
  background-color: var(--button-color);
}

.AAEASpotifyIcon {
  position: absolute;
  left: 240px;
  top: 160px;
  width: 55px;
  height: 50px;
  background-color: var(--button-color);
}

.AAEARecentlyPlayed {
  position: absolute;
  left: 60px;
  top: 110px;
  width: 130px;
  height: 140px;
  background-color: var(--button-color);
}

.HomeButton {
  position: absolute;
  left: 15px;
  top: 10px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}