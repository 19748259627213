.App {
  background-color: black;
  width: 630px;
  height: 1000px;
}


.AppTable {
  width: 100%;
  height: 50%;
  table-layout: fixed;
}

.AppTableRow {
  width: 100%;
  height: 15%;
}

.AppTableData {
  width: 50%;
  text-align: center;
  background-color: white;
}