.AppFrame {
  height: 1000px;
}

.AppChrome {
  height: auto;
  width: 1000px;
}

.AAASMediaPlayerBackButton {
  position: absolute;
  left: 15px;
  top: 190px;
  width: 180px;
  height: 55px;
  background-color: var(--button-color);
}

.AAASMediaPlayerSourcesButton {
  position: absolute;
  left: 15px;
  top: 125px;
  width: 180px;
  height: 55px;
  background-color: var(--button-color);
}

.AAASAmazonIcon {
  position: absolute;
  left: 355px;
  top: 185px;
  width: 175px;
  height: 140px;
  background-color: var(--button-color);
}

.AAASPandoraIcon {
  position: absolute;
  left: 535px;
  top: 185px;
  width: 175px;
  height: 140px;
  background-color: var(--button-color);
}

.AAASSpotifyIcon {
  position: absolute;
  left: 10px;
  top: 325px;
  width: 175px;
  height: 140px;
  background-color: var(--button-color);
}

.AAASRecentlyPlayed {
  position: absolute;
  left: 15px;
  top: 145px;
  width: 130px;
  height: 140px;
  background-color: var(--button-color);
}

.HomeButton {
  position: absolute;
  left: 15px;
  top: 70px;
  width: 50px;
  height: 50px;
  background-color: var(--button-color);
}